<app-workflow-step-layout>
  <ng-container info>
    <div class="info-container">
      <!-- readonly relevant fields -->
      <ng-container *ngIf="readOnly">
        <!-- batches -->
        <h3>Verwendete Chargen</h3>
        <app-batches-selector
          [readOnly]="true"
          [forms]="batchFormArray">
        </app-batches-selector>

        <h3>Verwendete statische Werte</h3>
        <div class="data-row">
          <div>Faktor Standard</div>
          <div>{{workflowStep.factorStandard}}</div>
        </div>

        <h3>Verwendete Formeln</h3>

        <div>µL Verdünnung = 20 / ( Konzentration * Factor Standard) / nM</div>
        <div>µL AD = 5 - µL Verdünnung</div>
      </ng-container>

      <ng-container *ngIf="!readOnly">
        <div *ngIf="batchFormArray">
          <ng-container *ngFor="let batchFormGroup of batchFormArray.controls">
            <app-batch-selector
              [readOnly]="readOnly"
              [form]="getFormGroupFromAbstractControl(batchFormGroup)"
              (onChange)="validateStep()"
              [showExpirationDate]="false">
            </app-batch-selector>
          </ng-container>
        </div>
      </ng-container>
    </div>
  </ng-container>

  <ng-container plate>
    <app-pcr-tube-viewer
      [readOnly]="readOnly"
      [form]="concentrationForm">
    </app-pcr-tube-viewer>
  </ng-container>
</app-workflow-step-layout>
