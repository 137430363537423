import {IWorkflowStepBase} from './workflow-step-base.model';
import {IWorkflowStepValidation} from './workflow-step-validation.model';
import {WorkflowStep} from '../../enums/WorkflowStep';
import {WorkflowInstanceModel} from '../workflow/workflow-instance.model';
import {WorkflowInstanceBaseModel} from '../workflow/workflow-instance-base.model';
import {
  WorkflowStepLibraryPostSequencingSavSummaryModel
} from './workflow-step-library-post-sequencing-sav-summary.model';
import {
  WorkflowStepLibraryPostSequencingRunParametersModel
} from './workflow-step-library-post-sequencing-run-parameters.model';
import {FileRecord} from '../file/FileRecord';

export class WorkflowStepLibraryPostSequencingModel implements IWorkflowStepBase, IWorkflowStepValidation {

  id: number;
  step: WorkflowStep;
  workflowInstance: WorkflowInstanceModel;
  isSkipped: boolean;

  selectedWorkflowInstances: WorkflowInstanceBaseModel[];

  savSummary: WorkflowStepLibraryPostSequencingSavSummaryModel;
  runParameters: WorkflowStepLibraryPostSequencingRunParametersModel;
  sampleSheet: FileRecord;

  constructor(id: number, step: WorkflowStep, workflowInstance: WorkflowInstanceModel,
              savSummary: WorkflowStepLibraryPostSequencingSavSummaryModel,
              runParameters: WorkflowStepLibraryPostSequencingRunParametersModel, sampleSheet: FileRecord, isSkipped: boolean) {
    this.id = id;
    this.step = step;
    this.workflowInstance = workflowInstance;
    this.isSkipped = isSkipped;
    this.selectedWorkflowInstances = workflowInstance.libraryWorkflowInstances ?? [];
    this.savSummary = WorkflowStepLibraryPostSequencingSavSummaryModel.fromData(savSummary);
    this.runParameters = WorkflowStepLibraryPostSequencingRunParametersModel.fromData(runParameters);
    this.sampleSheet = FileRecord.fromData(sampleSheet);
  }

  isValid(): boolean {
    // there is no validation needed here
    return !!this.savSummary && !!this.runParameters;
  }

}
