import {IWorkflowStepBase} from "../models/workflow-step/workflow-step-base.model";
import {WorkflowStep} from "../enums/WorkflowStep";
import {WorkflowStepPreparationModel} from "../models/workflow-step/workflow-step-preparation.model";
import {WorkflowStepSampleSelectionModel} from "../models/workflow-step/workflow-step-sample-selection.model";
import {WorkflowStepDnaExtractionModel} from "../models/workflow-step/workflow-step-dna-extraction.model";
import {
  WorkflowStepMeasurementOnGlomaxFirstModel
} from "../models/workflow-step/workflow-step-measurement-on-glomax-first.model";
import {WorkflowStepQuantificationFirstModel} from "../models/workflow-step/workflow-step-quantification-first.model";
import {WorkflowStepAddingChecksModel} from "../models/workflow-step/workflow-step-adding-checks.model";
import {WorkFlowStepTargetPcrModel} from "../models/workflow-step/work-flow-step-target-pcr.model";
import {WorkflowStepIndexPcrSetupModel} from "../models/workflow-step/workflow-step-index-pcr-setup.model";
import {
  WorkflowStepNormalisationPreparationModel
} from "../models/workflow-step/workflow-step-normalisation-preparation.model";
import {WorkflowStepNormalisation} from "../models/workflow-step/workflow-step-normalization.model";
import {WorkflowStepTargetPoolingModel} from "../models/workflow-step/workflow-step-target-pooling.model";
import {WorkflowStepPurificationModel} from "../models/workflow-step/workflow-step-purification.model";
import {
  WorkflowStepLibraryPlateSelectionModel
} from "../models/workflow-step/workflow-step-library-plate-selection.model";
import {
  WorkflowStepMeasurementOnGlomaxSecondModel
} from "../models/workflow-step/workflow-step-measurement-on-glomax-second.model";
import {WorkflowStepQuantificationSecondModel} from "../models/workflow-step/workflow-step-quantification-second.model";
import {
  WorkflowStepLibraryNormalisationAndPooling
} from "../models/workflow-step/workflow-step-library-normalisation-and-pooling";
import {WorkflowStepLibraryAgilantModel} from "../models/workflow-step/workflow-step-library-agilant.model";
import {WorkflowStepLibraryQubitModel} from "../models/workflow-step/workflow-step-library-qubit.model";
import {
  WorkflowStepLibraryNgsSequencingModel
} from "../models/workflow-step/workflow-step-library-ngs-sequencing.model";
import {
  WorkflowStepLibraryPostSequencingModel
} from "../models/workflow-step/workflow-step-library-post-sequencing.model";
import {
  WorkflowStepDnaExtractionSwabLysisModel
} from "../models/workflow-step/workflow-step-dna-extraction-swab-lysis.model";
import {WorkflowStepFinishedModel} from "../models/workflow-step/workflow-step-finished.model";
import {WorkflowInstanceModel} from "../models/workflow/workflow-instance.model";
import {PlateModel} from "../models/Plate/plate.model";
import {Workflow} from "../enums/Workflow";
import {
  WorkflowStepLibraryIndexPurificationModel
} from "../models/workflow-step/workflow-step-library-index-purification.model";
import {WorkflowStepLibraryPoolingModel} from "../models/workflow-step/workflow-step-library-pooling.model";

export class WorkflowStepUtils {

  static initWorkflowStepFromData(data: any): IWorkflowStepBase {
    if (!data) return null;
    data.workflowInstance = WorkflowInstanceModel.fromData(data.workflowInstance);

    /**
     * by the workflow 384 in some steps we process 4 file
     * it can happen that not all 4 file process in the same time
     * we display for the user always 4 placeholder plates.
     * if any processed plate provided depends on the plate index ( 1 based ) assign to the correct array position
     */
    if (data.step !== WorkflowStep.FINISHED && data.workflowInstance.workflow === Workflow.WORKFLOW_384 && (!data.plates || data.plates.length < 4)) {
      const plates = PlateModel.initPlaceHolderArray(data.workflowInstance.workflow, data.step);

      if (plates?.length !== data.plates?.length) {
        data.plates?.forEach((p: PlateModel) => {
          plates[p.plateIndex - 1] = p;
        });

        if (data.step !== WorkflowStep.PURIFICATION) {
          data.plates = plates;
        }

      }
    }

    switch (data.step as WorkflowStep) {
      case WorkflowStep.PREPARATION:
        return new WorkflowStepPreparationModel(data.id, data.step, data.workflowInstance, data.printerState, data.isSkipped);
      case WorkflowStep.SAMPLE_SELECTION:
        return new WorkflowStepSampleSelectionModel(data.id, data.step, data.workflowInstance, data.plates, data.isSkipped);
      case WorkflowStep.DNA_EXTRACTION_SWAB:
        return new WorkflowStepDnaExtractionModel(data.id, data.step, data.workflowInstance, data.plates, data.batches, data.isSkipped);
      case WorkflowStep.DNA_EXTRACTION_SWAB_LYSIS:
        return new WorkflowStepDnaExtractionSwabLysisModel(data.id, data.step, data.workflowInstance, data.plates, data.batches, data.isSkipped);
      case WorkflowStep.ADDING_CHECKS:
        return new WorkflowStepAddingChecksModel(data.id, data.step, data.workflowInstance, data.batches, data.plates, data.isSkipped);
      case WorkflowStep.QUANTIFICATION_FIRST:
        return new WorkflowStepQuantificationFirstModel(data.id, data.step, data.workflowInstance, data.plates, data.batches, data.isSkipped);
      case WorkflowStep.TARGET_PCR:
        return new WorkFlowStepTargetPcrModel(data.id, data.step, data.workflowInstance, data.plates, data.batches, data.isSkipped);
      case WorkflowStep.MEASUREMENT_GLOMAX_FIRST:
        return new WorkflowStepMeasurementOnGlomaxFirstModel(data.id, data.step, data.workflowInstance, data.plates, data.batches, data.cutoffValue, data.glomaxSlope, data.glomaxConstant, data.dnaSetIn, data.isSkipped);
      case WorkflowStep.NORMALIZATION_PREPARATION:
        return new WorkflowStepNormalisationPreparationModel(data.id, data.step, data.workflowInstance, data.plates, data.concentration, data.targetVolume, data.isSkipped);
      case WorkflowStep.NORMALIZATION:
        return new WorkflowStepNormalisation(data.id, data.step, data.workflowInstance, data.plates, data.batches, data.isSkipped);
      case WorkflowStep.TARGET_POOLING:
        return new WorkflowStepTargetPoolingModel(data.id, data.step, data.workflowInstance, data.checkBarcodes, data.checkRun, data.plates, data.isSkipped);
      case WorkflowStep.PURIFICATION:
        return new WorkflowStepPurificationModel(data.id, data.step, data.workflowInstance, data.batches, data.plates, data.isSkipped);
      case WorkflowStep.INDEX_PCR:
        return new WorkflowStepIndexPcrSetupModel(data.id, data.step, data.workflowInstance, data.plates, data.batches, data.cyclerRunThroughs, data.isSkipped);
      case WorkflowStep.WORKFLOW_SELECTION:
        return new WorkflowStepLibraryPlateSelectionModel(data.id, data.step, data.workflowInstance, data.isSkipped);
      case WorkflowStep.MEASUREMENT_GLOMAX_SECOND:
        return new WorkflowStepMeasurementOnGlomaxSecondModel(data.id, data.step, data.workflowInstance, data.plates, data.batches, data.cutoffValue, data.glomaxSlope, data.glomaxConstant, data.dnaSetIn, data.isSkipped);
      case WorkflowStep.QUANTIFICATION_SECOND:
        return new WorkflowStepQuantificationSecondModel(data.id, data.step, data.workflowInstance, data.plates, data.isSkipped);
      case WorkflowStep.NORMALIZATION_AND_POOLING:
        return new WorkflowStepLibraryNormalisationAndPooling(data.id, data.step, data.workflowInstance, data.file,
          data.batches, data.factorStandard, data.finalConcentration, data.plates, data.cutoffMin,
          data.cutoffMax, data.isSkipped);
      case WorkflowStep.INDEX_PURIFICATION:
        return new WorkflowStepLibraryIndexPurificationModel(data.id, data.step, data.workflowInstance, data.file, data.isSkipped);
      case WorkflowStep.QUALITY_ASSURANCE_AGILENT:
        return new WorkflowStepLibraryAgilantModel(data.id, data.step, data.workflowInstance, data.file, data.batches, data.isSkipped);
      case WorkflowStep.QUALITY_ASSURANCE_QUBIT:
        return new WorkflowStepLibraryQubitModel(data.id, data.step, data.workflowInstance, data.batches, data.factorStandard, data.concentrations, data.isSkipped);
      case WorkflowStep.POOLING:
        return new WorkflowStepLibraryPoolingModel(data.id, data.step, data.workflowInstance, data.indexPoolsCombined, data.isSkipped);
      case WorkflowStep.NGS_SEQUENCING:
        return new WorkflowStepLibraryNgsSequencingModel(data.id, data.step, data.workflowInstance, data.batches, data.isSkipped);
      case WorkflowStep.POST_SEQUENCING:
        return new WorkflowStepLibraryPostSequencingModel(data.id, data.step, data.workflowInstance, data.savSummary, data.runParameters, data.sampleSheet, data.isSkipped);
      case WorkflowStep.FINISHED:
        return new WorkflowStepFinishedModel(data.id, data.step, data.workflowInstance, data.finishedAt, data.finishedBy, data.isSkipped);
      default: {
        throw new Error(`Unknown class type: ${data.step}`);
      }
    }
  }

}
