import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FileUploadComponent } from './file-upload/file-upload.component';
import {FontAwesomeModule} from "@fortawesome/angular-fontawesome";
import {DialogModule} from "primeng/dialog";
import {TableModule} from "primeng/table";
import {CheckboxModule} from "primeng/checkbox";
import { FileComponent } from './file/file.component';
import {DirectivesModule} from "../../directives/directives.module";
import {CustomPipeModule} from "../../pipes/custom-pipe.module";



@NgModule({
  declarations: [
    FileUploadComponent,
    FileComponent
  ],
    exports: [
        FileUploadComponent,
        FileComponent
    ],
    imports: [
        CommonModule,
        FontAwesomeModule,
        DialogModule,
        TableModule,
        CheckboxModule,
        DirectivesModule,
        CustomPipeModule
    ]
})
export class FileUploadModule { }
