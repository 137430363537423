import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {PlateMode} from "../../enums/PlateMode";
import {Workflow} from "../../enums/Workflow";
import {PlateModel} from "../../models/Plate/plate.model";
import {WorkflowInstanceModel} from "../../models/workflow/workflow-instance.model";
import {PositionModel} from "../../models/Plate/position.model";

@Component({
  selector: 'app-plate-view',
  template: `
      <div class="plate-detail-container" *ngIf="this.selectedPlate">
          <div style="width: 100%" [ngStyle]="plateSize === Workflow.WORKFLOW_96 ? {'height': '98%'} : {}">
              <app-plate
                      [workflow]="plateSize"
                      [name]="workflowInstance?.workflowNumber + (selectedPlate?.suffix ?  '_' + selectedPlate?.suffix : '')"
                      [plate]="this.selectedPlate"
                      [plateMode]="plateMode"
                      [showBackButton]="Workflow.WORKFLOW_384 === workflowInstance.workflow && plates.length > 1"
                      (onPositionClick)="positionSelected($event)"
                      (onBackButtonClicked)="clearSelectedPlateSelection()"
                      [ignoreHeightBoundary]="plateSize === Workflow.WORKFLOW_384"
                      [showPositiveControlInOrange]="showPositiveControlInOrange">
              </app-plate>
          </div>
      </div>

      <div class="plate-list-container"
           *ngIf="!this.selectedPlate">
          <div *ngFor="let plate of plates"
               class="plate-container_48_percent"
               [ngClass]="plate?.id ? 'cursor-pointer' : ''"
               (click)="onPlateClicked(plate)">
              <app-plate
                      [workflow]="plateSize"
                      [name]="workflowInstance?.workflowNumber + (plate?.suffix ?  '_' + plate?.suffix : '')"
                      [plateMode]="plateMode"
                      [plate]="plate"
                      [ignoreHeightBoundary]="true"
                      [showPositiveControlInOrange]="showPositiveControlInOrange">
              </app-plate>
          </div>
      </div>
  `
})
export class PlateViewComponent implements OnInit {
  PlateMode = PlateMode;
  Workflow = Workflow;

  // the selected plate can also be set from outside
  @Input() selectedPlate: PlateModel;
  @Input() plates: PlateModel[] = [];
  @Input() plateMode: PlateMode;
  @Input() showPositiveControlInOrange: boolean = false;
  @Input() plateSize: Workflow = Workflow.WORKFLOW_96;
  @Input() workflowInstance: WorkflowInstanceModel;
  @Output() onPositionSelected = new EventEmitter<PositionModel>();
  @Output() onPlateSelected = new EventEmitter<PlateModel>();
  @Output() onClearPositionSelected = new EventEmitter<any>();

  ngOnInit(): void {
    if (this.plates.length == 1) {
      this.selectedPlate = this.plates[0];
    }
  }

  clearSelectedPlateSelection(): void {
    this.selectedPlate = null;
    this.onClearPositionSelected.emit();
    this.onPlateSelected.emit();
  }

  onPlateClicked(plate: PlateModel) {
    if (!plate.id) return;
    this.selectedPlate = plate;
    this.onPlateSelected.emit(plate);
  }

  positionSelected(position: PositionModel) {
    this.onPositionSelected.emit(position);
  }

}
