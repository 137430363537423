import {Component, Input, OnInit} from '@angular/core';
import {WorkflowInstanceModel} from "../../../../models/workflow/workflow-instance.model";
import {PlateModel} from "../../../../models/Plate/plate.model";
import {inOutAnimation} from "../../../../animations/in-out.animation";
import {WorkFlowStepTargetPcrModel} from "../../../../models/workflow-step/work-flow-step-target-pcr.model";
import {PlateMode} from "../../../../enums/PlateMode";

import {faArrowLeft, faCheck, faPlay} from "@fortawesome/free-solid-svg-icons";


import {CyclerRunThroughService} from "../../../../services/cycler-run-through.service";
import {
  CyclerRunThroughModel,
  CyclerRunTroughFormGroup
} from "../../../../models/cycler-run-trough/cycler-run-through.model";
import {AbstractControl, FormArray, FormGroup, UntypedFormArray} from "@angular/forms";
import {SelectItem} from "../../../../models/select-item.model";
import {CyclerRunThroughState} from "../../../../enums/CyclerRunThroughState";
import {WorkflowDetailClientService} from "../../../dialog/workflow-detail-dialog/workflow-detail-client.service";
import {PositionModel} from "../../../../models/Plate/position.model";
import {BatchModel} from "../../../../models/batch/batch.model";
import {Workflow} from "../../../../enums/Workflow";


@Component({
  selector: 'app-target-pcr-setup',
  templateUrl: './target-pcr-setup.component.html',
  styleUrls: ['./target-pcr-setup.component.scss'],
  animations: [inOutAnimation]
})
export class TargetPcrSetupComponent implements OnInit {

  Workflow = Workflow;
  faArrowLeft = faArrowLeft;
  faCheck = faCheck;
  faPlay = faPlay;

  PlateMode = PlateMode;

  @Input() workflowInstance: WorkflowInstanceModel;
  @Input() workflowStep: WorkFlowStepTargetPcrModel;
  @Input() readOnly: boolean = false;

  selectedPosition: PositionModel;

  selectedPlate: PlateModel;
  cyclerRunThroughFormArray: FormArray<FormGroup<CyclerRunTroughFormGroup & { suffix: AbstractControl<string> }>>;
  batchFormArray: UntypedFormArray;

  cyclerStateOptions: SelectItem[];

  constructor(private cyclerRunThroughService: CyclerRunThroughService,
              public workflowDetailClientService: WorkflowDetailClientService) {
    this.workflowDetailClientService.isStepValid$.next(false);
  }

  ngOnInit(): void {
    this.fetchCyclerRunThroughList();
    this.cyclerStateOptions = CyclerRunThroughState.getAllAsSelectItem();
    this.batchFormArray = BatchModel.initFormArrayForBatchSelection(this.workflowStep.batches, true, false);
  }

  onPlateClicked(plate: PlateModel): void {
    this.selectedPlate = plate
  }

  clearSelection(): void {
    this.selectedPlate = null;
    this.selectedPosition = null;
  }

  private fetchCyclerRunThroughList(): void {
    this.cyclerRunThroughService.getAllByWorkflowStepId(this.workflowStep.id)
      .subscribe({
        next: (resp) => {
          let runThroughModels: { cyclerRunThrough: CyclerRunThroughModel, plateSuffix: string }[] = [];
          // check for all plate if run through already exist
          this.workflowStep.plates.forEach(plate => {
            const crt = resp.find(c => c.plateId === plate.id);
            // cycler run through already exist
            if (crt) {
              runThroughModels.push({cyclerRunThrough: crt, plateSuffix: plate.suffix});
            } else {
              // no cycler run through exist -> create new
              const newCrt = new CyclerRunThroughModel();
              newCrt.plateId = plate.id;
              newCrt.workflowStepId = this.workflowStep.id;
              runThroughModels.push({cyclerRunThrough: newCrt, plateSuffix: plate.suffix});
            }
          });
          this.cyclerRunThroughFormArray = CyclerRunThroughModel.initForm(runThroughModels);
          this.validateStep();
        }
      });
  }

  validateStep(): void {
    const allConfirmedAndOk = this.cyclerRunThroughFormArray.getRawValue().every(cr => cr.confirmed && cr.state === CyclerRunThroughState.OK);
    this.workflowStep.batches = BatchModel.getBatchesFromBatchSelectionFormArray(this.batchFormArray);
    this.workflowDetailClientService.isStepValid$.next(allConfirmedAndOk && this.workflowStep.isValid());
  }

  onCellClick(position: PositionModel) {
    this.selectedPosition = position;
  }

}
