import {Component, Input, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {FileUploadValidator} from "../../../../validators/file-upload-validator";
import {MessageService} from "../../../../services/message.service";
import {WorkflowInstanceModel} from "../../../../models/workflow/workflow-instance.model";
import {
  WorkflowStepQuantificationSecondModel
} from "../../../../models/workflow-step/workflow-step-quantification-second.model";
import {WorkflowDetailClientService} from "../../../dialog/workflow-detail-dialog/workflow-detail-client.service";
import {WorkflowStep} from "../../../../enums/WorkflowStep";
import {PlateModel} from "../../../../models/Plate/plate.model";
import {PlateMode} from "../../../../enums/PlateMode";
import {faCheck, faTimes} from "@fortawesome/free-solid-svg-icons";
import {FormUtil} from "../../../../utils/form.util";
import {FileType} from "../../../../enums/FileType";
import {PlateService} from "../../../../services/plate.service";
import {Workflow} from "../../../../enums/Workflow";

@Component({
  selector: 'app-quantification-second',
  templateUrl: './quantification-second.component.html',
  styleUrls: ['./quantification-second.component.scss']
})
export class QuantificationSecondComponent implements OnInit {

  WorkflowStep = WorkflowStep;
  readonly NEW_FILE = 'newFile';
  protected readonly PlateMode = PlateMode;
  fileForm: FormGroup;

  @Input() workflowInstance: WorkflowInstanceModel;
  @Input() workflowStep: WorkflowStepQuantificationSecondModel;
  @Input() readOnly: boolean = false;

  selectedPlate: PlateModel;

  faCheck = faCheck;
  faTimes = faTimes;

  constructor(private messageService: MessageService,
              private plateService: PlateService,
              public workflowDetailClientService: WorkflowDetailClientService) {
  }

  ngOnInit(): void {
    this.initFilesForm();
    this.workflowDetailClientService.isStepValid$.next(this.workflowStep.isValid());

    if (this.workflowInstance?.workflow === Workflow.WORKFLOW_96 && this.workflowStep.plates.length === 1) {
      this.selectedPlate = this.workflowStep.plates[0];
    }
  }

  saveUpload(): void {
    if (this.fileForm.valid && Object.values(this.fileForm.value[this.NEW_FILE])?.length) {
      const documentsForm: FormData = FormUtil.toFormData(this.fileForm.value);
      this.plateService.uploadFileToPlate(this.selectedPlate.id, FileType.INTEGRA, documentsForm)
        .subscribe(resp => {
          if (resp.uploadedFile) {
            const index = this.workflowStep.plates.findIndex(p => p.id === this.selectedPlate.id);
            this.workflowStep.plates[index].uploadedFile = resp.uploadedFile;
            this.selectedPlate.uploadedFile = resp.uploadedFile;
            this.initFilesForm();
            this.validateStep();
          }
        })
    } else {
      FormUtil.markFormControlsAsTouchedAndDirty(this.fileForm);
      this.messageService.addErrorMessage('Bitte wählen Sie zuerst Dateien aus!');
    }
  }

  onRemoveFile() {
    if (this.selectedPlate && this.selectedPlate.id) {
      this.plateService.removeUploadedFileFromPlate(this.selectedPlate.id)
        .subscribe((resp) => {
          if (resp) {
            const index = this.workflowStep.plates.findIndex(p => p.id === this.selectedPlate.id);
            this.workflowStep.plates[index].uploadedFile = null;
            this.selectedPlate.uploadedFile = null;
            this.validateStep();
          }
        });
    }
  }

  onPlateClicked(plate: PlateModel) {
    this.selectedPlate = plate;
  }

  clearSelection(): void {
    this.selectedPlate = null;
  }

  private validateStep(): void {
    this.workflowDetailClientService.isStepValid$.next(this.workflowStep.isValid());
  }

  private initFilesForm(): void {
    this.fileForm = new FormGroup({
      newFile: new FormControl(null, [Validators.required, FileUploadValidator.maxFileSizeValidator(10000000)])
    });
  }

  getWorkflowNameByIndex(i: number): string {
    return this.workflowInstance?.workflowNumber + (this.workflowStep.plates[i]?.suffix ?  '_' + this.workflowStep.plates[i]?.suffix : '');
  }

  protected readonly Workflow = Workflow;
}
